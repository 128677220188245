import { makeObservable, observable, flow, action } from "mobx";
import { api } from "@/lib/services";
import { ApiRoutes } from "@/lib/routes";
import { toast } from "sonner";

import AddActivitySchema from "@/areas/compliance/schemas/AddActivitySchema";

export default class AddActivityStore {
  visible = false;
  processing = false;

  constructor(parentStore) {
    makeObservable(this, {
      visible: observable,
      processing: observable,
      show: action,
      hide: action,
      toggle: action,
      save: flow,
    });

    this.parentStore = parentStore;
  }

  get canAdd() {
    return (
      this.parentStore.control &&
      (this.parentStore.control.status === ComplianceConstants.Control.Status.Draft ||
        this.parentStore.control.status === ComplianceConstants.Control.Status.Active) &&
      (this.parentStore.controlPermissionStore.isCoordinator || this.parentStore.controlPermissionStore.isResponsible)
    );
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  toggle(visible) {
    this.visible = visible === true ? true : visible === false ? false : !this.visible;
  }

  *save(data, options) {
    try {
      this.processing = true;

      const parseResult = AddActivitySchema.safeParse(data);
      if (!parseResult.success) {
        console.error(parseResult);
        return parseResult;
      }

      const response = yield api.post(ApiRoutes.forControlActivities(), data);
      this.visible = false;
      toast.success("Control activity created successfully");
      this.parentStore.controlActivitiesStore.load({ search: "", page: 1 });
      return { success: true, data: response.data };
    } catch (e) {
      toast.error("Error while creating control activity", {
        description: "Unexpected error, please try again later.",
      });
      return { success: false, error: e };
    } finally {
      this.processing = false;
    }
  }
}
