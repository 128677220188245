import { api } from "@/lib/services";
import { makeObservable, observable, flow, computed, action } from "mobx";
import { v4 as uuid } from "uuid";
import globalRouter from "@/globalRouter";
import { add } from "date-fns";

import { SessionManagementStore } from "./SessionManagementStore";

export const userUrl = "/auth/user";
const loginUrl = "/auth/login";
const silentLoginUrl = "/auth/silent-login";
const defaultLogoutUrl = "/auth/logout";

export class AuthenticationStore {
  user = null;
  iframeId = null;
  initCalled = false;
  lastApiCall = null;
  logoutUrl = defaultLogoutUrl;
  processing = false;
  expiresAt = null;

  constructor(rootStore) {
    makeObservable(this, {
      user: observable,
      isAuthenticated: computed,
      init: flow,
      login: flow,
      processing: observable,
      loginRedirect: action,
      isAdmin: computed,
      isAgent: computed,
      isMember: computed,
    });

    this.rootStore = rootStore;
    this.iframeId = `auth-silent-login-${uuid()}`;

    this.sessionStore = new SessionManagementStore(this);
  }

  get isAdmin() {
    return this.user != null && this.user.role === "admin";
  }

  get isAgent() {
    return this.user != null && this.user.role === "agent";
  }

  get isMember() {
    return this.user != null && this.user.role === "member";
  }

  get isAuthenticated() {
    return this.user != null;
  }

  *init() {
    if (this.initCalled) {
      return;
    }

    this.initCalled = true;

    api.interceptors.request.use(
      (config) => {
        this.lastApiCall = new Date();
        // Do something before request is sent
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    api.interceptors.response.use(
      (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          // && globalRouter.navigate
          //globalRouter.navigate("/session-expired");
          this.sessionStore.setStatus("expired");
        }
        return Promise.reject(error);
      }
    );

    let resp;
    try {
      resp = yield fetch(userUrl, {
        headers: {
          "X-CSRF": 1,
        },
      });
      if (resp.ok) {
        let claims = yield resp.json();

        if (claims) {
          this.log("user logged in");
          this.updateUser(claims);
        } else {
          this.log("user not logged in");
        }
      } else if (resp.status === 401) {
        this.log("user not logged in");

        // if we've detected that the user is not already logged in, we can attempt a silent login
        // this will trigger a normal OIDC request in an iframe using prompt=none.
        // if the user is already logged into IdentityServer, then the result will establish a session in the BFF.
        // this whole process avoids redirecting the top window without knowing if the user is logged in or not.
        const silentLoginResult = yield this.silentLogin();

        // the result is a boolean letting us know if the user has been logged in silently
        this.log("silent login result: " + silentLoginResult, silentLoginResult);

        // if we now have a user logged in silently
        if (silentLoginResult) {
          resp = yield fetch(userUrl, { headers: { "X-CSRF": 1 } });
          if (resp.ok) {
            let claims = yield resp.json();

            if (claims) {
              this.updateUser(claims);

              return;
            }
          }
        }

        this.loginRedirect();
      }
    } catch (e) {
      this.log("error checking user status", e);
    }

    this.sessionStore.init();
  }

  // this will trigger the silent login and return a promise that resolves to true or false.
  silentLogin() {
    const iframeSelector = `#${this.iframeId}`;
    const timeout = 5000;

    return new Promise((resolve, reject) => {
      function onMessage(e) {
        // look for messages sent from the BFF iframe
        if (e.data && e.data["source"] === "bff-silent-login") {
          window.removeEventListener("message", onMessage);
          // send along the boolean result
          resolve(e.data.isLoggedIn);
        }
      }

      // listen for the iframe response to notify its parent (i.e. this window).
      window.addEventListener("message", onMessage);

      // we're setting up a time to handle scenarios when the iframe doesn't return immediately (despite prompt=none).
      // this likely means the iframe is showing the error page at IdentityServer (typically due to client misconfiguration).
      window.setTimeout(() => {
        window.removeEventListener("message", onMessage);
        // we can either just treat this like a "not logged in"
        resolve(false);
        // or we can trigger an error, so someone can look into the reason why
        // reject(new Error("timed_out"));
      }, timeout);

      // send the iframe to the silent login endpoint to kick off the workflow
      document.querySelector(iframeSelector).src = silentLoginUrl;
    });
  }

  loginRedirect(redirectUrl) {
    this.processing = true;
    window.location = `${loginUrl}?returnUrl=${encodeURIComponent(
      redirectUrl ?? window.location.pathname + window.location.search
    )}`;
  }

  *login() {
    const silentLoginResult = yield this.silentLogin();

    if (silentLoginResult) {
      let resp = yield fetch(userUrl, { headers: { "X-CSRF": 1 } });
      if (resp.ok) {
        let claims = yield resp.json();

        if (claims) {
          this.updateUser(claims);

          return;
        }
      }
    }

    this.loginRedirect();
  }

  updateUser(claims) {
    this.log("user claims", claims);

    this.user = {
      id: claims.find((claim) => claim.type === "sub")?.value,
      userId: claims.find((claim) => claim.type === "uid")?.value,
      name: claims.find((claim) => claim.type === "name")?.value,
      jobTitle: claims.find((claim) => claim.type === "job_title")?.value,
      email: claims.find((claim) => claim.type === "email")?.value,
      tenantId: claims.find((claim) => claim.type === "tid")?.value,
      role: claims.find((claim) => claim.type === "tmr")?.value,
      claims,
    };

    this.expiresAt = add(new Date(), {
      seconds: claims.find((claim) => claim.type === "bff:session_expires_in")?.value ?? 0,
    });

    let logoutUrlClaim = claims.find((claim) => claim.type === "bff:logout_url");
    if (logoutUrlClaim) {
      this.logoutUrl = logoutUrlClaim.value;
    }
  }

  logout() {
    window.location = `${this.logoutUrl}${this.logoutUrl.indexOf("?") === -1 ? "?" : "&"}returnUrl=${encodeURIComponent(
      "/signed-out"
    )}`;
  }

  log(message, ...args) {
    console.log(`[auth] ${message}`, ...args);
  }
}
