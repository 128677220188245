import { ApiRoutes } from "@/lib/routes";

import CreateRiskStore from "@/areas/risks/stores/CreateRiskStore";
import BrowseRisksStore from "@/areas/risks/stores/BrowseRisksStore";
import RiskHeatmapStore from "@/areas/risks/stores/RiskHeatmapStore";
import BrowseTreatmentsStore from "@/areas/risks/stores/BrowseTreatmentsStore";

import BrowseTargetRolesStore from "@/areas/subscriptions/stores/BrowseTargetRolesStore";
import BrowseTargetFieldsStore from "@/areas/fields/stores/BrowseTargetFieldsStore";

export default class RiskRootStore {
  constructor(parentStore) {
    this.parentStore = parentStore;

    this.createRiskStore = new CreateRiskStore(this);
    this.browseRisksStore = new BrowseRisksStore(this);
    this.riskHeatmapStore = new RiskHeatmapStore(this);
    this.browseTreatmentsStore = new BrowseTreatmentsStore(this);

    this.browseRolesStore = new BrowseTargetRolesStore(this, ApiRoutes.forRiskRoles("risk"));
    this.browseTreatmentRolesStore = new BrowseTargetRolesStore(this, ApiRoutes.forRiskRoles("treatment"));
    this.browseRiskTypeRolesStore = new BrowseTargetRolesStore(this, ApiRoutes.forRiskRoles("risk-type"));
    this.browseFieldsStore = new BrowseTargetFieldsStore(this, ApiRoutes.forRiskFields());
  }
}
