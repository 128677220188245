import { api } from "@/lib/services";
import { makeObservable, observable, flow, action } from "mobx";
import { userUrl } from "./AuthenticationStore";

export class SessionManagementStore {
  status = "none";
  hidePrompt = false;
  processing = false;
  refreshTimerId = null;
  watcherTimerId = null;
  expiresIn = null;

  constructor(parentStore) {
    makeObservable(this, {
      status: observable,
      processing: observable,
      init: flow,
      checkSession: flow,
      watchSession: flow,
      extendSession: flow,
      setStatus: action,
      expiresIn: observable,
    });

    this.parentStore = parentStore;
  }

  *init() {
    this.parentStore.log("SessionManagementStore init");

    if (this.refreshTimerId) {
      clearInterval(this.refreshTimerId);
    }

    if (this.watcherTimerId) {
      clearInterval(this.watcherTimerId);
    }

    this.refreshTimerId = setInterval(() => {
      this.checkSession();
    }, 1000 * 60 * 5);

    this.watcherTimerId = setInterval(() => {
      this.watchSession();
    }, 1000 * 60 * 1);
  }

  *checkSession() {
    const resp = yield fetch(userUrl, {
      headers: {
        "X-CSRF": 1,
      },
    });

    if (resp.ok) {
      const claims = yield resp.json();
      if (claims) {
        this.parentStore.updateUser(claims);
        this.parentStore.log("session checked", this.parentStore.expiresAt);

        return;
      }
    }

    this.expiresIn = null;
    this.setStatus("expired");
  }

  *watchSession() {
    if (this.parentStore.expiresAt) {
      this.expiresIn = (this.parentStore.expiresAt.getTime() - new Date().getTime()) / 1000;
      if (this.expiresIn < 0) {
        this.setStatus("expired");
      } else if (this.expiresIn < 60) {
        this.setStatus("expired");
      } else if (this.expiresIn < 60 * 10) {
        this.setStatus("expiring");
      } else {
        this.setStatus("none");
      }
    }
  }

  *extendSession() {
    try {
      this.processing = true;
      const response = yield api.post("/account/extend-session", {});
      yield this.checkSession();
      yield this.watchSession();
      return response.status;
    } catch (e) {
      return e.response?.status;
    } finally {
      this.processing = false;
    }
  }

  setStatus(status) {
    this.status = status;
  }
}
