import { AlertTriangleIcon, LogInIcon } from "lucide-react";
import { Loader, Save } from "@/components/icons/IconMap";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

export default function SessionExpiringDialog({ visible, onCancel, onExtend, processing }) {
  return (
    <AlertDialog open={visible} className="">
      <AlertDialogOverlay className="bg-zinc-500/20 backdrop-blur-sm" />
      <AlertDialogContent className="sm:rounded-md">
        <AlertDialogHeader>
          <AlertDialogTitle className="flex items-center gap-2 leading-8">
            <AlertTriangleIcon className="h-5 w-5" /> Session Expiring Soon!
          </AlertDialogTitle>
          <AlertDialogDescription>Your current session is about to expire due to inactivity.</AlertDialogDescription>
          <AlertDialogDescription>
            If you wish to stay signed in or have unsaved work, click Stay Signed In now to extend your session.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onCancel}>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={onExtend} disabled={processing}>
            {processing ? <Loader variant="primary-foreground" size="xs" /> : <LogInIcon className="h-4 w-4" />}
            <span className="ml-2">Stay Signed In</span>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
