import { Loader, Save } from "@/components/icons/IconMap";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { AlertTriangleIcon, LogInIcon } from "lucide-react";

export default function SessionExpiredDialog({ visible, onCancel, onLogin, processing }) {
  return (
    <AlertDialog open={visible}>
      <AlertDialogOverlay className="bg-zinc-500/20 backdrop-blur-sm" />
      <AlertDialogContent className="sm:rounded-md">
        <AlertDialogHeader>
          <AlertDialogTitle className="flex items-center gap-2 leading-8">
            <AlertTriangleIcon className="h-5 w-5" /> Session Expired!
          </AlertDialogTitle>
          <AlertDialogDescription>
            Your current session has expired. This may be due to inactivity or because your session was too long. Please
            login again to continue.
          </AlertDialogDescription>
          <AlertDialogDescription>
            If you wish to save any unsaved work you can click Cancel and recover any unsaved work before continuing.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onCancel}>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={onLogin} disabled={processing}>
            {processing ? <Loader variant="primary-foreground" size="xs" /> : <LogInIcon className="h-4 w-4" />}
            <span className="ml-2">Login</span>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
